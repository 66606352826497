import { api } from "../services/api";
import {displayErrMsg} from "../utils/errors";
// import router from "../router";

export default {
  namespaced: true,
  state: {
    floors: [],
    floor: null,
  },

  mutations: {
    SAVE_FLOOR(state, data) {
      state.floor = data;
    },

    SET_FLOORS(state, data) {
      state.floors = data;
    },

    UPDATE_FLOOR(state, data) {
      const new_floors = [...state.floors];
      const index = new_floors.findIndex((floor) => {
        return floor.id === data.id;
      });

      new_floors[index] = data;

      state.floors = new_floors;
    },

    DELETE_FLOOR(state, id) {
      state.floors = state.floors.filter((floor) => {
        return floor.id !== id;
      });
    },

    async GET_FLOORS(state, { data }) {
      await api.get("buildings/" + data.building.id + "/floors").then((res) => {
        state.floors = res.data;
      })
      .catch((err) => {
        displayErrMsg(err.response.data.code, 'Andar', "GET_FLOORS");
      });
    },
  },

  getters: {
    floors: (state) => state.floors,
    floor: (state) => state.floor,
  },
  actions: {
    async getFloor(context, id) {
      await api
        .get("floors/" + id)
        .then((res) => {
          context.commit("SAVE_FLOOR", res.data);
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Andar', "GET_FLOOR");
        });
    },
    async updateFloor(context, data) {
      await api
        .put("buildings/" + data.building_id + "/floors", data)
        .then((res) => {
          context.commit("SAVE_FLOOR", res.data);
          context.commit("UPDATE_FLOOR", res.data);
          window.toastr.success("Alterado com sucesso", "Andar");
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Andar', "UPDATE_FLOOR");
        });
    },
    async getFloors(context, buildingId) {
      await api.get("buildings/" + buildingId + "/floors").then((res) => {
        context.commit("SET_FLOORS", res.data);
      })
      .catch((err) => {
        displayErrMsg(err.response.data.code, 'Andar', "GET_FLOORS");
      });
    },
    async deleteFloor(context, id) {
      await api
        .delete(`floors/${id}`)
        .then(() => {
          context.commit("DELETE_FLOOR", id);
          displayErrMsg('GLOBAL_SUCCESS_DELETE', 'Andar', "DELETE_FLOOR");
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Andar', "DELETE_FLOOR");
        });
    },
    async saveFloor(context, data) {
      if (!data.id) {
        await api
          .post("buildings/" + data.building_id + "/floors", data)
          .then((res) => {
            context.commit("SAVE_FLOOR", res.data);
            const new_floors = [...context.getters["floors"], res.data];
            context.commit("SET_FLOORS", new_floors);
            displayErrMsg('GLOBAL_SUCCESS_ADD', 'Andar', "SAVE_FLOOR");
          })
          .catch((err) => {
            displayErrMsg(err.response.data.code, 'Andar', "SAVE_FLOOR");
          });
      } else {
        context.dispatch("updateFloor", data);
      }
    },
  },
};
