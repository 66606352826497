import { api } from "../../services/api";
import { displayErrMsg } from "../../utils/errors";

export default {
  namespaced: true,
  state: {
    areas: [],
  },
  mutations: {
    async SAVE_EMPLOYEE_AREA(state, { data }) {
      await api
        .post(`customers/${data.customer_id}/employees/areas`, {
          area: data.area,
        })
        .then(() => {
          state.areas.push({ area: data.area });
          displayErrMsg("GLOBAL_SUCCESS_ADD", 'Colaborador - Área', 'SAVE_EMPLOYEE_AREA');
        })
        .catch((err) => {
          // if (err.response.data.subcode !== "TOKEN_EXPIRED") {
          //   window.toastr.error("Erro ao adicionar", "Colaborador - Área");
          // }
          displayErrMsg(err.response.data.code, 'Colaborador - Área', 'SAVE_EMPLOYEE_AREA');
        });
    },
    async DELETE_EMPLOYEE_AREA(state, { data }) {
      await api
        .delete(
          `customers/${
            data.customer_id
          }/employees/areas?area=${encodeURIComponent(data.area)}`
        )
        .then(() => {
          displayErrMsg("GLOBAL_SUCCESS_DELETE", 'Colaborador - Área', 'DELETE_EMPLOYEE_AREA');
          state.areas = state.areas.filter((area) => area.area !== data.area);
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Colaborador - Área', 'DELETE_EMPLOYEE_AREA');
        });
    },
    async GET_EMPLOYEES_AREAS(state, { data }) {
      await api
        .get(`customers/${data.customer_id}/employees/areas`)
        .then((res) => {
          state.areas = res.data;
        });
    },
  },
  getters: {
    areas: (state) => state.areas,
  },
  actions: {
    getEmployeeAreas(context, data) {
      context.commit("GET_EMPLOYEES_AREAS", { data });
    },
    deleteEmployeeAreas(context, data) {
      context.commit("DELETE_EMPLOYEE_AREA", { data });
    },
    saveEmployeeArea(context, data) {
      context.commit("SAVE_EMPLOYEE_AREA", { data });
    },
  },
};
