var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"can",rawName:"v-can",value:(['ADMIN', 'SYSADMIN', 'MANAGER', 'FRONTDESK', 'DASHBOARD', 'USER']),expression:"['ADMIN', 'SYSADMIN', 'MANAGER', 'FRONTDESK', 'DASHBOARD', 'USER']"}]},[_c('div',{staticClass:"row px-0"},[_c('TwInput',{attrs:{"label":"Busca"}},[_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('i',{staticClass:"fas fa-search"})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"form-control",staticStyle:{"max-width":"900px"},attrs:{"type":"search","id":"filterInput","placeholder":"Buscar"},domProps:{"value":(_vm.filter)},on:{"input":function($event){if($event.target.composing)return;_vm.filter=$event.target.value}}})],1)],1),_c('div',{staticClass:"col-2 d-flex flex-column-reverse pb-3"},[_c('b-button',{directives:[{name:"can",rawName:"v-can",value:(['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER']),expression:"['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER']"}],staticStyle:{"background-color":"#4e01d6"},attrs:{"id":"addEmployeebtn","href":"#"},on:{"click":() => {
            this.$router.push({ name: 'customer-employee-add' });
          }}},[_vm._v("Adicionar")])],1),_c('div',{staticClass:"col-2 d-flex flex-column-reverse pb-3"},[_c('b-button',{directives:[{name:"can",rawName:"v-can",value:(['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER']),expression:"['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER']"}],staticStyle:{"background-color":"#4e01d6"},attrs:{"href":"#","data-toggle":"modal","data-target":"#modalDeleteEmployees"}},[_vm._v("Excluir")])],1),_c('div',{staticClass:"col-2 d-flex flex-column-reverse pb-3"},[_c('b-button',{directives:[{name:"can",rawName:"v-can",value:(['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER']),expression:"['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER']"}],staticStyle:{"background-color":"#4e01d6"},attrs:{"href":"#"},on:{"click":() => {
            this.$router.push({ name: 'customer-import-employee' });
          }}},[_vm._v("Importar")])],1)],1),_c('div',[_c('Modal',{attrs:{"id":"modalDeleteEmployee","title":"Excluir colaborador","msg":'Tem certeza que deseja excluir ' + _vm.employeeDelete.name + '?',"button":"Excluir","method":_vm.deleteEmployee}}),_c('Modal',{attrs:{"id":"modalDeleteEmployees","title":"Excluir colaboradores","msg":'Tem certeza que deseja excluir todos os colaboradores ?',"button":"Excluir","method":_vm.deleteEmployees}})],1),_c('b-table',{ref:"selectableTable",staticClass:"mt-3 rounded",attrs:{"responsive":"sm","striped":"","borderless":"","hover":"","no-sort-reset":"","sticky-header":"610px","show-empty":"","empty-text":"Nenhum registro encontrado","empty-filtered-text":"Nenhum registro encontrado para essa busca","head-variant":"light","per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-by":_vm.sortByEmployees,"busy":_vm.load,"filter":_vm.filter,"fields":_vm.fieldsEmployees,"items":_vm.customerEmployees},on:{"update:sortBy":function($event){_vm.sortByEmployees=$event},"update:sort-by":function($event){_vm.sortByEmployees=$event},"filtered":_vm.onFiltered,"context-changed":_vm.changeBsTableState},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(){return _vm._l((_vm.fieldsEmployees),function(field){return _c('col',{key:field.key,style:({
          width: field.key === 'actions' ? '100px' : '550px',
        })})})},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('strong',[_c('i',{staticClass:"fas fa-sync-alt fa-spin"})])])]},proxy:true},{key:"cell(cellphone)",fn:function(data){return [_vm._v(_vm._s(_vm.formatCellphone(data.item.cellphone)))]}},{key:"cell(tag)",fn:function(data){return [(data.item.tag)?_c('span',[_vm._v(" Tag: "+_vm._s(data.item.tag.tag_label)),_c('br'),_vm._v(" Mac: "+_vm._s(data.item.tag.mac_address)),_c('br')]):_vm._e()]}},{key:"cell(tasg)",fn:function(data){return [(data.item.tag)?_c('span',[_vm._v(" Tag: "+_vm._s(data.item.tag.tag_label)),_c('br'),_vm._v(" Mac: "+_vm._s(data.item.tag.mac_address)),_c('br')]):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [_c('a',{directives:[{name:"can",rawName:"v-can",value:(['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER']),expression:"['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER']"}],staticClass:"mr-3",attrs:{"id":"deleteEmployee","href":"#","data-toggle":"modal","data-target":"#modalDeleteEmployee","title":"Excluir"},on:{"click":function($event){_vm.employeeDelete = data.item}}},[_c('i',{staticClass:"far fa-trash-alt iconspace"})]),_c('router-link',{staticClass:"mr-3",attrs:{"id":"addtag","to":{
          name: 'customer-employee-add-tags',
          params: {
            customerId: _vm.customer ? _vm.customer.id : -1,
            employeeId: data.item.id,
          },
        },"title":"Associar tags"}},[_c('i',{staticClass:"fas fa-tag iconspace"})]),_c('router-link',{directives:[{name:"can",rawName:"v-can",value:(['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER']),expression:"['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER']"}],attrs:{"to":{
          name: 'customer-employee-edit',
          params: {
            customerId: _vm.customer ? _vm.customer.id : -1,
            employeeId: data.item.id,
          },
        },"title":"Editar","id":"customer-employee-edit-a"}},[_c('i',{staticClass:"fas fa-pen iconspace",attrs:{"id":"editEmployee"}})])]}}])}),_c('div',{staticClass:"row"},[(_vm.rows)?_c('div',{staticClass:"col-12 col-md-4 mb-2 pt-1"},[_c('b-pagination',{staticClass:"pagination",attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"align":"fill","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e(),_c('div',{staticClass:"col-12 col-md-8"},[_c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[_c('b-dropdown',{attrs:{"variant":"primary","text":`Linhas: ${_vm.perPage}`},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}},[_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":() => {
                this.perPage = 10;
              }}},[_vm._v("10")]),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":() => {
                this.perPage = 20;
              }}},[_vm._v("20")]),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":() => {
                this.perPage = 50;
              }}},[_vm._v("50")]),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":() => {
                this.perPage = '1000';
              }}},[_vm._v("1000")])],1)],1)])]),(_vm.customerEmployees && _vm.customerEmployees.length)?_c('div',{staticClass:"col-12 mb-2 pr-4"},[_c('a',{staticClass:"text-danger",attrs:{"data-toggle":"modal","data-target":"#modalDeleteEmployees","href":"#"}})]):_vm._e(),_c('div',{staticClass:"col-12 pr-4 mb-2"},[_c('router-link',{attrs:{"to":{
        name: 'customer-employee-add',
        params: { customerId: _vm.customer ? _vm.customer.id : -1 },
      },"id":"customer-employee-add-a"}})],1),_c('div',{staticClass:"col-12 pr-4"},[_c('router-link',{attrs:{"to":{
        name: 'customer-import-employee',
        params: { customerId: _vm.customer ? _vm.customer.id : -1 },
      },"id":"customer-import-employee-a"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }