<template>
  <div>
    <div>
      <b-modal v-if="zone" v-model="modalShow" hide-footer>
        <template #modal-header="{ close }">
          <h5>Zona</h5>
          <b-button variant="link" @click="close()">
            <span>X</span>
          </b-button>
        </template>
        <form @submit.prevent="saveZone">
          <div class="row">
            <TwInput label="Nome" col_lg="12" :required="true">
              <input
                id="zones-zone-name"
                v-model="zone.zone_name"
                maxlength="255"
                required
                type="text"
                class="form-control"
                placeholder="Nome"
              />
            </TwInput>
          </div>
          <div class="row">
            <TwInput label="Descrição" col_lg="12" :required="true">
              <input
                id="zones-zone-desc"
                v-model="zone.description"
                maxlength="255"
                required
                type="text"
                class="form-control"
                placeholder="Descricao"
              />
            </TwInput>
          </div>
          <div class="row">
            <TwInput label="Cor" col_lg="12" :required="true">
              <input
                type="color"
                class="form-control form-control-color"
                id="exampleColorInput"
                v-model="zone.zone_color"
                title="escolha uma cor para a zona"
              />
            </TwInput>
          </div>
          <div class="row p-1">
            <button
              id="zones-save-btn"
              class="btn btn-primary px-5"
              :disabled="load"
            >
              <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
            </button>
          </div>
        </form>
      </b-modal>

      <b-modal v-if="geofence" size="xl" v-model="posicionarShow" hide-footer>
        <template #modal-header="{ close }">
          <h5>Posicionar Geofence</h5>
          <b-button variant="link" @click="close()">
            <span>X</span>
          </b-button>
        </template>
        <div v-if="floor && floor.map.file_archive">
          <span style="color: grey"> Clique na posição desejada </span>

          <CanvasImage
            v-if="floor"
            class="mt-3"
            width="1000"
            height="1000"
            :map="floor.map.file_archive"
            type="onePoint"
            @imgSize="setImgSize"
            @point="pointConfirm"
          />
        </div>
        <div v-else>Andar não possui planta</div>
      </b-modal>

      <b-modal v-if="geofence" v-model="modalGeofenceShow" hide-footer>
        <template #modal-header="{ close }">
          <h5>Geofence</h5>
          <b-button variant="link" @click="close()">
            <span>X</span>
          </b-button>
        </template>
        <form @submit.prevent="saveGeofence">
          <div class="row">
            <TwInput label="Nome" col_lg="12" :required="true">
              <input
                id="zones-geof-geoname"
                required
                maxlength="255"
                v-model="geofence.geo_name"
                type="text"
                class="form-control"
                placeholder="Nome"
              />
            </TwInput>
          </div>
          <div class="row">
            <TwInput label="Posição X" col_lg="4" :required="true">
              <input
                id="zones-geof-posx"
                required
                v-model="geofence.x_position"
                type="number"
                class="form-control"
                placeholder="posição x"
                disabled
              />
            </TwInput>
            <TwInput label="Posição Y" col_lg="4" :required="true">
              <input
                id="zones-geof-posy"
                required
                v-model="geofence.y_position"
                type="number"
                class="form-control"
                placeholder="posição y"
                disabled
              />
            </TwInput>
            <TwInput label="Posicionar" col_lg="4">
              <b-button
                @click="posicionarShow = !posicionarShow"
                variant="info"
              >
                Abrir planta
              </b-button>
            </TwInput>
          </div>
          <div class="row">
            <TwInput label="Raio (m)" col_lg="12" :required="true">
              <span v-if="maxRadius" style="color: #959595">
                Raio máximo sugerido: {{ Math.trunc(maxRadius) }} m</span
              >
              <input
                id="zones-geof-radius"
                required
                v-model="geofence.radius"
                type="number"
                min="0"
                max="9999"
                class="form-control"
                placeholder="Raio"
              />
            </TwInput>
          </div>
          <div class="row px-2 mt-4">
            <button
              id="zones-save-btn2"
              class="btn btn-primary px-5"
              :disabled="load"
            >
              <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
            </button>
          </div>
        </form>
      </b-modal>

      <b-modal
        v-if="availableGateways && geofence"
        size="xl"
        v-model="modalGatewaysShow"
        hide-footer
      >
        <template #modal-header="{ close }">
          <h5>Gateways</h5>
          <b-button variant="link" @click="close()">
            <span>X</span>
          </b-button>
        </template>
        <form @submit.prevent="assignGateway(selectedGateways, filtergeofence)">
          <div class="row">
            <TwInput label="Gateways Disponíveis">
              <div class="pr-1 pl-1 pb-3">
                <input
                  id="zones-gateways-search-f"
                  v-model="filterGateways"
                  type="search"
                  class="form-control col-12 w-100"
                  placeholder="Buscar"
                />
              </div>
              <div class="row pr-1 pl-1">
                <div class="col-12 pb-3">
                  <button
                    id="zones-selall-btn"
                    type="button"
                    class="btn btn-light table-header-button col-12"
                    @click="onSelectAll"
                    :disabled="load"
                  >
                    <i class="fas fa-chevron-right"></i>
                    <i class="fas fa-chevron-right"></i></button
                  ><b-table
                    class="custom-scroll mt-2"
                    thead-class="user-table-header"
                    empty-text="Não há gateways disponíveis"
                    head-variant="light"
                    borderless
                    no-sort-reset
                    show-empty
                    :busy="load"
                    :fields="[
                      {
                        key: 'gateway_name',
                        sortable: true,
                        label: 'Gateways disponíveis',
                      },
                    ]"
                    :items="filtergeofence"
                    :filter="filterGateways"
                    @row-clicked="onSelectGateway"
                  >
                    <template v-slot:table-busy>
                      <div class="text-center my-2">
                        <strong>
                          <i class="fas fa-sync-alt fa-spin"></i>
                        </strong>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </TwInput>
            <TwInput label="Gateways Associados">
              <div class="pr-1 pl-1 pb-3">
                <input
                  id="zones-gateways-search-fass"
                  v-model="filterAssignGateways"
                  type="search"
                  class="form-control col-12 w-100"
                  placeholder="Buscar"
                />
              </div>

              <div class="row pr-1 pl-1">
                <div class="col-12 pb-3">
                  <button
                    id="zones-desall-btn"
                    @click="onDeselectAll"
                    type="button"
                    class="btn btn-light table-header-button col-12"
                    :disabled="load"
                  >
                    <i class="fas fa-chevron-left"></i>
                    <i class="fas fa-chevron-left"></i>
                  </button>
                  <b-table
                    class="custom-scroll mt-2"
                    thead-class="user-table-header"
                    empty-text="Não há gateways associados"
                    head-variant="light"
                    borderless
                    no-sort-reset
                    show-empty
                    :busy="load"
                    :items="selectedGateways"
                    :fields="[
                      {
                        key: 'gateway_name',
                        sortable: true,
                        label: 'Gateways Associados',
                      },
                    ]"
                    :filter="filterAssignGateways"
                    @row-clicked="onDeselectGateway"
                  >
                    <template v-slot:table-busy>
                      <div class="text-center my-2">
                        <strong>
                          <i class="fas fa-sync-alt fa-spin"></i>
                        </strong>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </TwInput>
          </div>

          <button id="zones-save-btn3" class="btn btn-primary float-right">
            Salvar
          </button>
        </form>
      </b-modal>
    </div>
    <div class="row d-flex justify-content-between">
      <div class="col-6 px-2 mt-3">
        <h3 style="padding-bottom: 1px">Zonas</h3>
        <div class="row" style="col-2; padding-bottom: 5px; display:flex; justify-content:space-between;">
          <span style="color: #a3a3a3; padding: 10px">
            Clique em uma zona para listar os geofences</span
          >

          <b-button
            v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
            variant="link"
            @click="addZone"
            style="right:9px; color: white"
            class="btn-primary add-user col-3"
          >
            <span class="d-none d-md-block d-lg-block"> Adicionar zona</span>
          </b-button>
        </div>
        <b-table
          borderless
          :fields="fieldsZones"
          :items="zones"
          class="rounded mt-1"
          selectable
          select-mode="single"
          show-empty
          @row-selected="changeZone"
          ref="tableZone"
        >
          <template #empty>
            <span v-if="!zone">Selecione uma zona</span>
            <span v-else>Não existem zonas cadastrados nesse andar</span>
          </template>
          <template v-slot:table-colgroup>
            <col
              v-for="field in fieldsZones"
              :key="field.key"
              :style="{
                width: field.key === 'actions' ? '100px' : '550px',
              }"
            />
          </template>
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <strong>
                <i class="fas fa-sync-alt fa-spin"></i>
              </strong>
            </div>
          </template>

          <template v-slot:cell(map)="data">{{
            data.item.map.description
          }}</template>

          <template v-slot:cell(zone_color)="data">
            <i
              :style="`color:${data.item.zone_color};font-size:18px`"
              class="fas fa-square"
            ></i>
          </template>
          <template v-slot:cell(actions)="data">
            <a
              @click="zoneDelete = data.item"
              href="#"
              class="mr-3"
              data-toggle="modal"
              data-target="#modalDeleteZone"
              v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
              :id="'excludeZone' + data.item.id"
              title="Excluir"
            >
              <i class="far fa-lg fa-trash-alt"></i>
            </a>
            <a
              @click="editZone(data.item)"
              class="mr-3"
              style="cursor: pointer"
              title="Editar"
              :id="'editZones_' + data.item.id"
              v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
            >
              <i class="fas fa-lg fa-pen"></i>
            </a>
            <b-button
              @click="openModalGeofence(data.item)"
              class="mr-3"
              style="cursor: pointer"
              title="Adicionar geofence"
              :id="'addGeofence_' + data.item.id"
              v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
              ><i class="fa-solid fa-lg fa-location-dot"><i class="fa-solid fa-circle-plus" style="font-size:10px;color:green"></i></i></b-button
            >
          </template>
        </b-table>
      </div>
      <div class="col-6 px-2 mt-3">
        <h3 style="padding-bottom: 1px">Geofences</h3>
        <div class="row" style="pl-2;  padding: 0px 0px 18px 10px">
          <span v-if="!selectedZone" style="color: #a3a3a3">
            Clique em uma zona para listar os geofences</span
          >
          <span v-else style="color: #a3a3a3s">Lista de Geofences</span>
        </div>
        <b-table
          striped
          borderless
          :fields="fieldsGeofences"
          :items="geofences"
          class="rounded mt-1"
          show-empty
        >
          <template #empty>
            <span v-if="!selectedZone"
              >Selecione uma zona para ver os geofences</span
            >
            <span v-else>Não existem geofences cadastrados nessa zona</span>
          </template>
          <template v-slot:table-colgroup>
            <col
              v-for="field in fieldsGeofences"
              :key="field.key"
              :style="{
                width: field.key === 'actions' ? '100px' : '550px',
              }"
            />
          </template>
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <strong>
                <i class="fas fa-sync-alt fa-spin"></i>
              </strong>
            </div>
          </template>

          <template v-slot:cell(map)="data">{{
            data.item.map.description
          }}</template>

          <template v-slot:cell(zone_color)="data">
            <i
              :style="`color:${data.item.zone_color};font-size:18px`"
              class="fas fa-square"
            ></i>
          </template>
          <template v-slot:cell(actions)="data">
            <a
              @click="geoDelete = data.item"
              href="#"
              class="mr-3"
              data-toggle="modal"
              data-target="#modalDeleteGeo"
              title="Excluir"
              v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
            >
              <i class="far fa-trash-alt"></i>
            </a>
            <a
              @click="editGeo(data.item)"
              class="mr-3"
              style="cursor: pointer"
              title="Editar"
              v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
            >
              <i class="fas fa-pen"></i>
            </a>
            <a
              @click="openModalGateways(data.item)"
              class="mr-3"
              style="cursor: pointer"
              title="Gerenciar gateways"
              v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
            >
              <i class="fas fa-satellite-dish"></i>
            </a>
          </template>
        </b-table>
      </div>

      <Modal
        id="modalDeleteGeo"
        title="Excluir Geofence"
        v-if="geoDelete"
        v-bind:msg="
          'Tem certeza que deseja excluir ' + geoDelete.geo_name + '?'
        "
        button="Excluir"
        :method="deleteGeo"
      />
      <Modal
        id="modalDeleteZone"
        title="Excluir Zona"
        v-if="zoneDelete"
        v-bind:msg="
          'Tem certeza que deseja excluir ' + zoneDelete.zone_name + '?'
        "
        button="Excluir"
        :method="deleteZone"
      />
    </div>
    <div class="row d-flex flex-row-reverse">
      <button
        id="zones-seeplant-btn"
        @click="goNext"
        type="button"
        class="btn btn-primary"
      >
        Atualizar / Ver a planta <i class="fas fa-arrow-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
import CanvasImage from "@/components/CanvasImage.vue";
import Modal from "@/components/Modal.vue";
import TwInput from "@/components/TwInput.vue";

export default {
  components: {
    CanvasImage,
    Modal,
    TwInput,
  },
  data() {
    return {
      filtergeofence: [],
      filterGateways: null,
      filterAssignGateways: null,
      selectedGateways: [],
      unaassignGateways: [],
      assignGateways: [],
      modalShow: false,
      modalGeofenceShow: false,
      modalGatewaysShow: false,
      posicionarShow: false,
      selectedZone: null,
      imgWidth: 0,
      imgHeight: 0,
      maxRadius: 0,
      zone: {
        description: null,
        zone_name: null,
        zone_color: null,
        geofences: [],
      },
      geofence: {
        geo_name: null,
        geo_type: "STATIC",
        location: null,
        radius: 0,
        x_position: null,
        y_position: null,
      },
      zoneDelete: null,
      geoDelete: null,
      copyFilterGeofence: [],
      copySelectedGateways: [],
      file: "",
      filePreview: "",
      fileName: "",
      fieldsZones: [
        { key: "zone_name", sortable: true, label: "Nome" },
        { key: "description", sortable: true, label: "Descrição" },
        { key: "zone_color", sortable: true, label: "Cor" },
        { key: "actions", label: "Ações", class: "actions" },
      ],
      fieldsGeofences: [
        { key: "geo_name", sortable: true, label: "Nome" },
        { key: "geo_type", sortable: true, label: "Tipo" },
        { key: "location", sortable: true, label: "Localização" },
        { key: "radius", sortable: true, label: "Raio (m)" },
        { key: "actions", label: "Ações", class: "actions" },
      ],
    };
  },
  async mounted() {
    if (this.$route.params.buildingId) {
      await this.$store.dispatch(
        "floor/getFloors",
        this.$route.params.buildingId
      );
    }
  },
  methods: {
    async goNext() {
      await this.$store.dispatch("floor/getFloor", this.floor.id);

      await this.$store.dispatch("zone/getZones", this.floor?.id);
      this.$store.commit("zone/CLEAR_ALL_GEOFENCES");
      await this.zones.forEach(async (zone) => {
        await this.$store.dispatch("zone/getGeofencesByZone", zone.id);
      });
      this.$emit("next");
    },
    goFloors() {
      this.$emit("back");
    },
    onSelectAll() {
      if (this.filtergeofence.length === 0) {
        window.frontMsg("FRNT_ACTION_WARNING", {
          reason: "não há Coletores disponíveis",
        });
        return;
      }
      this.selectedGateways.push(...this.filtergeofence);
    },
    onSelectGateway(item) {
      this.selectedGateways.push(item);
    },
    onDeselectAll() {
      if (this.selectedGateways.length === 0) {
        window.frontMsg("FRNT_ACTION_WARNING", {
          reason: "não há Coletores associados",
        });
        return;
      }
      const copy = [...this.selectedGateways];
      this.filtergeofence.push(...copy);
      this.selectedGateways.splice(0, this.selectedGateways.length);
    },
    onDeselectGateway(item) {
      this.selectedGateways.splice(this.selectedGateways.indexOf(item), 1);
    },
    setImgSize(size) {
      this.imgWidth = size.imgWidth;
      this.imgHeight = size.imgHeight;
    },
    pointConfirm(point) {
      this.geofence.x_position = point.x / this.floor.map.scale;
      this.geofence.y_position = point.y / this.floor.map.scale;
      this.maxRadius = Math.min(
        point.imgWidth / this.floor.map.scale / 2,
        point.imgHeight / this.floor.map.scale / 2
      );
      this.posicionarShow = false;
    },
    addZone() {
      this.zone = {
        description: null,
        zone_name: null,
        zone_color: "#000000",
        geofences: [],
        floor_id: this.floor.floor_id,
      };
      this.modalShow = !this.modalShow;
    },
    isZoneValid(sendZone) {
      if (!sendZone.zone_name || sendZone.zone_name.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Nome" });
        return false;
      }
      if (!sendZone.description || sendZone.description.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Descrição" });
        return false;
      }
      return true;
    },
    isGeofenceValid(sendGeofence) {
      if (!sendGeofence.geo_name || sendGeofence.geo_name.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Nome do geofence" });
        return false;
      }
      if (!sendGeofence.radius) {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Raio" });
        return false;
      }

      if (!sendGeofence.x_position || !sendGeofence.y_position) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "Geofence", extra: "Posicione o Geofence na planta" }
        );
        return false;
      }

      return true;
    },
    async saveZone() {
      const sendZone = { ...this.zone };

      if (!this.isZoneValid(sendZone)) {
        return;
      }

      await this.$store.dispatch("zone/saveZone", {
        floor_id: this.floor.id,
        sendZone,
      });
      this.modalShow = false;
    },
    async saveGeofence() {
      const sendGeofence = { ...this.geofence };
      if (!this.isGeofenceValid(sendGeofence)) {
        return;
      }

      await this.$store.dispatch("geofence/saveGeofence", {
        zone_id: this.geofence.zone_id,
        sendGeofence,
      });

      this.geofence = {
        geo_name: null,
        geo_type: "STATIC",
        location: null,
        radius: 0,
        x_position: null,
        y_position: null,
      };

      this.modalGeofenceShow = false;
    },
    saveGateways() {
      const sendGateways = { ...this.gateways };

      if (!this.isGatewaysValid(sendGateways)) {
        return;
      }

      this.modalGatewaysShow = false;
    },
    assignGateway() {
      this.assignGateways = this.selectedGateways.filter((e) => {
        return !this.copySelectedGateways?.some((f) => {
          return f.id === e.id;
        });
      });
      this.unaassignGateways = this.filtergeofence.filter((g) => {
        return !this.copyFilterGeofence?.some((f) => {
          return f.id === g.id;
        });
      });
      let gateway_list = this.assignGateways.map((element) => {
        return element.id;
      });

      if (this.assignGateways) {
        this.$store.dispatch("geofence/assignGateway", {
          customer_id: this.customerId,
          data: this.geofence,
          gateway_list,
        });
      }

      let unaassign_list = this.unaassignGateways.map((element) => {
        return element.id;
      });
      if (this.unaassignGateways) {
        this.$store.dispatch("geofence/unassignGateway", {
          customer_id: this.customerId,
          data: this.geofence,
          unaassign_list,
        });
      }

      this.modalGatewaysShow = false;
    },
    async editZone(zone) {
      await this.$store.dispatch("zone/getZone", zone.id);
      this.zone = { ...this.stored_zone };
      this.modalShow = true;
    },
    async deleteZone() {
      await this.$store.dispatch("zone/deleteZone", this.zoneDelete.id);
      this.zone = this.stored_zone;
    },
    async editGeo(geo) {
      await this.$store.dispatch("geofence/getGeo", geo.id);
      this.geofence = { ...this.stored_geofence };
      this.modalGeofenceShow = true;
    },
    async deleteGeo() {
      await this.$store.dispatch("geofence/deleteGeofence", this.geoDelete.id);
      this.geofence = this.stored_geofence;
    },
    changeZone(data) {
      if (data.length) {
        this.selectedZone = data[0]?.id;
      } else {
        this.selectedZone = null;
        this.$store.commit("geofence/SET_GEOFENCES", []);
      }
    },
    openModalGeofence(data) {
      this.$refs.tableZone.selectRow(data.id-1);

      this.geofence = {
          geo_name: null,
          geo_type: "STATIC",
          location: data.zone_name,
          radius: 0,
          x_position: null,
          y_position: null,
          zone_id: data.id,
        };

      this.modalGeofenceShow = true;
    },
    async openModalGateways(data) {
      await this.$store.dispatch("geofence/getGeo", data.id);
      await this.$store.dispatch(
        "geofence/getAvailableGateways",
        this.customerId
      );
      this.geofence = this.stored_geofence;
      if (this.geofence.gateways != null) {
        this.selectedGateways = this.geofence.gateways;
      } else {
        this.selectedGateways = [];
      }
      this.filtergeofence = this.assigngeofence.filter((e) => {
        return !this.geofence.gateways?.some((f) => {
          return f.id === e.id;
        });
      });
      this.copyFilterGeofence = [...this.filtergeofence];
      this.copySelectedGateways = [...this.selectedGateways];
      this.modalGatewaysShow = true;
    },
  },
  watch: {
    async floor() {
      if (this.floor.id) {
        await this.$store.commit("geofence/SET_GEOFENCES", []);
      }
    },
    async selectedZone() {
      if (this.selectedZone) {
        await this.$store.dispatch("geofence/getByZone", this.selectedZone);
      }
    },
    selectedGateways() {
      this.filtergeofence = this.assigngeofence.filter((e) => {
        return !this.geofence.gateways?.some((f) => {
          return f.id === e.id;
        });
      });
    },
    modalGeofenceShow() {
      if (!this.modalGeofenceShow) {
        this.geofence = {
          geo_name: null,
          geo_type: "STATIC",
          location: null,
          radius: 0,
          x_position: null,
          y_position: null,
        };
      }
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    floor() {
      return this.$store.getters["floor/floor"];
    },
    stored_zone() {
      return this.$store.getters["zone/zone"];
    },
    assigngeofence() {
      if (this.availableGateways)
        return this.availableGateways.filter(
          (gateway) => !this.selectedGateways.includes(gateway)
        );

      return [];
    },
    stored_geofence() {
      return this.$store.getters["geofence/geofence"];
    },
    zones() {
      return this.$store.getters["zone/zones"];
    },
    geofences() {
      return this.$store.getters["geofence/geofences"];
    },
    customerId() {
      return this.$route.params.customerId;
    },
    buildingId() {
      return this.$route.params.buildingId;
    },
    availableGateways() {
      return this.$store.getters["geofence/availableGateways"];
    },
  },
};
</script>
