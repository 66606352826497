var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Breadcrumb',{attrs:{"breadcrumbList":_vm.breadcrumbList}}),_vm._m(0),_c('div',{staticClass:"col-12 col-sm-12 p-0 col-md-12"},[_c('div',{staticClass:"card direct-chat direct-chat-primary"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"p-4 bg-white rounded"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pr-4"},[_c('div',{staticClass:"form-group"},[_c('UploadExcel',{attrs:{"on-success":_vm.handleSuccess,"before-upload":_vm.beforeUpload,"draggable":true}})],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tableData.length),expression:"tableData.length"}]},[_vm._m(1),_c('b-table',{ref:"selectableTable",staticClass:"mt-3 rounded",attrs:{"responsive":"sm","striped":"","borderless":"","hover":"","fields":_vm.fields,"items":_vm.tableData,"busy":!_vm.verifyImport,"selectable":"","select-mode":"multi","editableFields":""},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([_vm._l((_vm.editableFields),function(field){return {key:`cell(${field.key})`,fn:function({ item }){return [(field.key === 'gender')?_c('select',{directives:[{name:"model",rawName:"v-model",value:(item[field.key]),expression:"item[field.key]"}],key:field.key,staticClass:"form-control",attrs:{"id":'g-'+field.key},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(item, field.key, $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.generos),function(opt){return _c('option',{key:opt.gender},[_vm._v(" "+_vm._s(opt)+" ")])}),0):_vm._e(),(
                    field.key !== 'gender' &&
                    field.key !== 'cpf' && 
                    field.key !== 'cellphone'
                  )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(item[field.key]),expression:"item[field.key]"}],key:field.key,staticClass:"form-control",attrs:{"type":"text","id":'imp-'+field.key},domProps:{"value":(item[field.key])},on:{"change":function($event){return _vm.validate(_vm.tableData)},"input":function($event){if($event.target.composing)return;_vm.$set(item, field.key, $event.target.value)}}}):_vm._e(),(
                    field.key == 'cellphone' 
                  )?_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('+NNNNNNNNNNNNNNN'),expression:"'+NNNNNNNNNNNNNNN'"},{name:"model",rawName:"v-model",value:(item[field.key]),expression:"item[field.key]"}],key:field.key,staticClass:"form-control",attrs:{"type":"text","id":'imp-'+field.key},domProps:{"value":(item[field.key])},on:{"change":function($event){return _vm.validate(_vm.tableData)},"input":function($event){if($event.target.composing)return;_vm.$set(item, field.key, $event.target.value)}}}):_vm._e(),(
                    field.key == 'cpf' 
                  )?_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('NNN.NNN.NNN-NN'),expression:"'NNN.NNN.NNN-NN'"},{name:"model",rawName:"v-model",value:(item[field.key]),expression:"item[field.key]"}],key:field.key,staticClass:"form-control",attrs:{"type":"text","id":'imp-'+field.key},domProps:{"value":(item[field.key])},on:{"change":function($event){return _vm.validate(_vm.tableData)},"input":function($event){if($event.target.composing)return;_vm.$set(item, field.key, $event.target.value)}}}):_vm._e(),(item.errors)?_c('span',{key:'erro-' + field.key,staticStyle:{"color":"red"}},[_vm._v(_vm._s(item.errors[field.key]))]):_vm._e()]}}}),{key:"cell(selected)",fn:function({ rowSelected }){return [(rowSelected)?[_c('span',{staticClass:"check-symbol",attrs:{"aria-hidden":"true"}},[_vm._v("✓")]),_c('span',{staticClass:"sr-only"},[_vm._v("Selected")])]:[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(" ")]),_c('span',{staticClass:"sr-only"},[_vm._v("Not selected")])]]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('strong',[_c('i',{staticClass:"fas fa-sync-alt fa-spin"})])])]},proxy:true},{key:"table-colgroup",fn:function(){return _vm._l((_vm.fields),function(field){return _c('col',{key:field.key,style:({
                    width:
                      field.key === 'selected' || field.key === 'actions'
                        ? '150px'
                        : '550px',
                  })})})},proxy:true},{key:"cell(invalid)",fn:function(data){return [(data.item.invalid)?_c('span',{staticClass:"valid-import badge badge-danger"},[_vm._v("Inválido")]):_vm._e(),(!data.item.invalid)?_c('span',{staticClass:"valid-import badge badge-success"},[_vm._v("Válido")]):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [(
                    _vm.storage
                      ? _vm.storage.getItem('email') != data.item.email
                      : false
                  )?_c('a',{staticClass:"mr-3",attrs:{"href":"#","data-toggle":"modal","data-target":"#modalDeleteUser","title":"Excluir"},on:{"click":function($event){_vm.user = data.item}}},[_c('i',{staticClass:"far fa-trash-alt"})]):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'user-edit', params: { user: data.item } },"title":"Editar"}},[_c('i',{staticClass:"fas fa-pen"})])]}}],null,true)}),_c('div',{staticClass:"row p-1"},[_c('button',{staticClass:"btn btn-outlined btn-primary mr-2",attrs:{"id":"selectAll","type":"button"},on:{"click":_vm.selectAllRows}},[_vm._v(" Selecionar tudo ")]),_c('button',{staticClass:"btn btn-outlined btn-primary mr-2",attrs:{"id":"desselectAll","type":"button"},on:{"click":_vm.clearSelected}},[_vm._v(" Desselecionar tudo ")]),_c('button',{staticClass:"btn btn-outlined btn-danger mr-2",attrs:{"type":"button","id":"btn-delete-selected"},on:{"click":_vm.deleteSelected}},[_vm._v(" Remover selecionados ")]),_c('button',{staticClass:"btn btn-success px-5",attrs:{"id":"btn-saveEmployees-selected","disabled":_vm.load},on:{"click":_vm.saveEmployees}},[(_vm.load)?_c('i',{staticClass:"fas fa-sync-alt fa-spin"}):_vm._e(),_vm._v(" Salvar ")])])],1),(!_vm.tableData.length)?_c('span',{staticStyle:{"color":"blue"}},[_vm._v(" Selecione um arquivo de colaboradores | "),_c('a',{attrs:{"href":_vm.item.loc,"download":""}},[_vm._v("Modelo de importação xlsx")])]):_vm._e()])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-header px-0"},[_c('h1',{staticClass:"title-I"},[_vm._v("Importar colaboradores")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-header px-0"},[_c('h4',[_vm._v("Colaboradores encontrados")])])
}]

export { render, staticRenderFns }