<template>
  <div v-can="['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']">
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1 class="title-I">Editar colaborador</h1>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card direct-chat direct-chat-primary employee">
          <form @submit="updateEmployee">
            <div class="card-body">
              <div class="pt-4 pr-4 pb-2 pl-4 bg-white rounded">
                <div class="row">
                  <TwInput col_lg="12" label="Nome" required="false">
                    <input
                      v-model="employee.name"
                      required
                      type="text"
                      maxlength="55"
                      class="form-control"
                      placeholder="Nome do colaborador"
                      id="employeeName"
                    />
                  </TwInput>
                </div>
                <div class="row">
                  <TwInput label="E-mail">
                    <input
                      v-model="employee.email"
                      type="email"
                      maxlength="55"
                      class="form-control"
                      placeholder="E-mail"
                      id="employeeEmail"
                    />
                  </TwInput>
                  <TwInput label="Celular">
                    <input
                      v-model="employee.cellphone"
                      v-mask="'+(##) ## #####-####'"
                      type="text"
                      class="form-control"
                      placeholder="Celular"
                      id="employeeCellphone"
                    />
                  </TwInput>
                </div>
                <div class="row mb-2">
                  <TwInput label="CPF">
                    <input
                      v-model="employee.cpf"
                      v-mask="'###.###.###-##'"
                      type="text"
                      class="form-control"
                      placeholder="CPF"
                      id="employeeCpf"
                    />
                  </TwInput>
                  <TwInput label="Número de registro" required="false">
                    <input
                      v-model="employee.registration_number"
                      required
                      type="text"
                      maxlength="20"
                      class="form-control"
                      placeholder="Número de registro"
                      id="employeeRegistration"
                    />
                  </TwInput>
                </div>
                <div class="row mb-2">
                  <div class="col-12">
                    <div class="card direct-chat direct-chat-primary">
                      <div class="card-body custom-card overflow-visible">
                        <ul class="nav nav-tabs bg-main">
                          <li class="nav-item">
                            <a
                              class="nav-link first"
                              v-bind:class="{
                                active: this.tabSelected === 'functionalData',
                              }"
                              href="#"
                              v-on:click="changeTab('functionalData')"
                              aria-current="dados funcionais"
                              tabindex="-1"
                              >Dados funcionais</a
                            >
                          </li>
                          <li class="nav-item">
                            <a
                              id="anamnesis-tab"
                              class="nav-link"
                              v-bind:class="{
                                active: anamnesisSelected,
                              }"
                              v-on:click="changeTab('anamnesis')"
                              href="#"
                              aria-current="anamnese"
                              tabindex="-1"
                              >Anamnese</a
                            >
                          </li>
                        </ul>
                        <div class="p-4 bg-white rounded">
                          <div class="tab-content">
                            <div
                              class="tab-pane fade"
                              v-bind:class="{
                                show: anamnesisSelected,
                                active: anamnesisSelected,
                              }"
                            >
                              <div class="row">
                                <TwInput col_lg="4" label="Peso (kg)">
                                  <input
                                    v-model="employee.anamnesis_data.weight"
                                    type="number"
                                    class="form-control"
                                    placeholder="Peso"
                                    id="employeeWeight"
                                    :max="1000"
                                  />
                                </TwInput>
                                <TwInput col_lg="4" label="Altura (m)">
                                  <input
                                    v-model="employee.anamnesis_data.height"
                                    type="number"
                                    :step="0.01"
                                    :max="3"
                                    class="form-control"
                                    placeholder="Altura"
                                    id="employeeHeight"
                                  />
                                </TwInput>
                                <TwInput col_lg="4" label="Fumante">
                                  <select
                                    v-model="employee.anamnesis_data.smoking"
                                    class="form-control"
                                    id="smokerForm"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="S">Sim</option>
                                    <option value="N">Não</option>
                                  </select>
                                </TwInput>
                              </div>
                              <div class="row">
                                <TwInput
                                  col_lg="4"
                                  label="Doenças Pré-existentes"
                                >
                                  <div class="input-group">
                                    <input
                                      id="edit-diseases-list"
                                      type="text"
                                      class="form-control"
                                      v-model="diseasesList"
                                    />
                                    <div class="input-group-append">
                                      <button
                                        class="btn btn-outline-secondary dropdown-toggle"
                                        type="button"
                                        data-toggle="dropdown"
                                        id="diseaseDropdown"
                                      ></button>
                                      <div class="dropdown-menu">
                                        <span
                                          class="dropdown-item"
                                          v-for="(
                                            disease, index
                                          ) in preexisting_conditions_options"
                                          :key="index"
                                          v-on:click="selectDisease(disease)"
                                        >
                                          {{ disease }}
                                        </span>
                                        <span
                                          class="dropdown-item"
                                          href="#"
                                          v-on:click="selectDisease('Outra')"
                                          >Outra</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </TwInput>
                                <TwInput col_lg="4" label="Atividade Física">
                                  <select
                                    v-model="
                                      employee.anamnesis_data.physical_activity
                                    "
                                    class="form-control"
                                    id="physicalactivitySelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="0x">
                                      Não pratica exercícios
                                    </option>
                                    <option value="1x">1x por semana</option>
                                    <option value="2x">2x por semana</option>
                                    <option value="3x">3x por semana</option>
                                    <option value="4x">4x por semana</option>
                                    <option value="5+">5+ por semana</option>
                                  </select>
                                </TwInput>
                                <TwInput
                                  col_lg="4"
                                  label="Medicamentos de uso contínuo"
                                >
                                  <select
                                    v-model="
                                      employee.anamnesis_data
                                        .medicines_for_continuous_use
                                    "
                                    class="form-control"
                                    id="medicinesSelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="S">Sim</option>
                                    <option value="N">Não</option>
                                  </select>
                                </TwInput>
                              </div>
                              <div class="row">
                                <TwInput col_lg="4" label="Pressão arterial">
                                  <select
                                    v-model="
                                      employee.anamnesis_data
                                        .blood_pressure_level
                                    "
                                    class="form-control"
                                    id="pressureSelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option
                                      v-for="(
                                        blood_pressure_level, index
                                      ) in blood_pressure_level_options"
                                      :key="index"
                                      :value="blood_pressure_level.val"
                                    >
                                      {{ blood_pressure_level.option }}
                                    </option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Gravidez">
                                  <select
                                    v-model="employee.anamnesis_data.pregnancy"
                                    class="form-control"
                                    id="pregnancySelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="S">Sim</option>
                                    <option value="N">Não</option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Data (último ASO)">
                                  <input
                                    class="form-control"
                                    type="date"
                                    :max="
                                      new Date().toISOString().split('T')[0]
                                    "
                                    v-model="formatedLastOHC"
                                    id="ohcSelect"
                                  />
                                </TwInput>
                              </div>
                            </div>
                            <div
                              class="tab-pane fade"
                              v-bind:class="{
                                show: functionalDataSelected,
                                active: functionalDataSelected,
                              }"
                            >
                              <div class="row">
                                <TwInput col_lg="4" label="Área">
                                  <select
                                    v-model="employee.functional_data.area"
                                    class="form-control"
                                    id="areaSelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option
                                      v-for="(area, index) in area_options"
                                      :key="index"
                                      :value="area.toLowerCase()"
                                    >
                                      {{ area }}
                                    </option>
                                    <option value="other">Outra</option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Setor">
                                  <select
                                    v-model="
                                      employee.functional_data.department
                                    "
                                    class="form-control"
                                    id="departmentSelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option
                                      v-for="(
                                        department, index
                                      ) in department_options"
                                      :key="index"
                                      :value="department.toLowerCase()"
                                    >
                                      {{ department }}
                                    </option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Cargo">
                                  <select
                                    v-model="employee.functional_data.position"
                                    class="form-control"
                                    id="positionSelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option
                                      v-for="(
                                        position, index
                                      ) in position_options"
                                      :key="index"
                                      :value="position.toLowerCase()"
                                    >
                                      {{ position }}
                                    </option>
                                  </select>
                                </TwInput>
                              </div>
                              <div class="row">
                                <TwInput col_lg="4" label="Turno">
                                  <select
                                    v-model="
                                      employee.functional_data.work_shift
                                    "
                                    class="form-control"
                                    id="workshiftSelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option
                                      v-for="(
                                        work_shift, index
                                      ) in work_shift_options"
                                      :key="index"
                                      :value="work_shift.toLowerCase()"
                                    >
                                      {{ work_shift }}
                                    </option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Jornada (h)">
                                  <input
                                    id="edit-employee-work-shift"
                                    v-model="employee.functional_data.work_week"
                                    type="number"
                                    class="form-control"
                                    placeholder="44"
                                    :max="168"
                                  />
                                </TwInput>

                                <TwInput col_lg="4" label="Gestor">
                                  <input
                                    id="edit-employee-supervisor"
                                    v-model="employeeSupervisor"
                                    type="text"
                                    autocomplete="off"
                                    class="form-control filter-input"
                                    placeholder="Gestor"
                                  />
                                  <div
                                    v-if="filteredSupervisors.length"
                                    class="autocomplete"
                                  >
                                    <div
                                      class="sup"
                                      @click="onSelectSupervisor('none')"
                                    >
                                      <b>LIMPAR SELEÇÃO</b>
                                    </div>
                                    <div
                                      v-for="sup in filteredSupervisors"
                                      class="sup"
                                      :key="sup.id"
                                      @click="onSelectSupervisor(sup)"
                                    >
                                      <b>{{ sup.name }}</b>
                                    </div>
                                  </div>
                                </TwInput>
                              </div>
                              <div class="row">
                                <TwInput col_lg="4" label="Plano de saúde">
                                  <select
                                    v-model="
                                      employee.functional_data.health_plan
                                    "
                                    class="form-control"
                                    id="healthplanSelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="S">Sim</option>
                                    <option value="N">Não</option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Gênero">
                                  <select
                                    v-model="employee.gender"
                                    class="form-control"
                                    id="genderSelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="FEMININO">Feminino</option>
                                    <option value="MASCULINO">Masculino</option>
                                    <option value="NAO-IDENTIFICADO">
                                      Não informado
                                    </option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Data de nascimento">
                                  <input
                                    id="edit-employee-birth-date"
                                    class="form-control"
                                    type="date"
                                    :max="
                                      new Date().toISOString().split('T')[0]
                                    "
                                    v-model="formatedDateOfBirth"
                                  />
                                </TwInput>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-3 pl-2">
                  <button
                    id="saveEmployee"
                    class="btn btn-primary px-5"
                    :disabled="load"
                  >
                    <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import { validCPF } from "../../services/helpers";
import { formatDate, formatDateAPI } from "../../utils/format-fields";
import { nullEmptyProps } from "../../utils/format-json";
import "./style.scss";
import TwInput from "@/components/TwInput.vue";

export default {
  name: "EditEmployee",
  data: function () {
    return {
      breadcrumbList: [],
      tabSelected: "functionalData",
      preexisting_conditions_options: [
        "Asma",
        "Cardiopatia",
        "Diabetes",
        "Hepatite",
      ],
      work_shift_options: ["Administrativo", "Primeiro", "Segundo", "Terceiro"],
      blood_pressure_level_options: [
        { val: "BAIXO", option: "BAIXA" },
        { val: "NORMAL", option: "NORMAL" },
        { val: "ELEVADO", option: "ELEVADO" },
        { val: "HIPERTENSAO-ESTAGIO-1", option: "HIPERTENSÃO Estágio I" },
        { val: "HIPERTENSAO-ESTAGIO-2", option: "HIPERTENSÃO Estágio II" },
        { val: "CRISE-HIPERTENSIVA", option: "CRISE HIPERTENSIVA" },
      ],
      employee: {
        id: "",
        name: "",
        email: "",
        cellphone: "",
        cpf: "",
        registration_number: "",
        gender: "",
        date_of_birth: "",
        anamnesis_data: {
          height: "",
          weight: "",
          smoking: "",
          preexisting_conditions: [],
          physical_activity: "",
          medicines_for_continuous_use: "",
          blood_pressure_level: "",
          pregnancy: "",
        },
        functional_data: {
          area: "",
          department: "",
          position: "",
          work_shift: "",
          work_week: "",
          supervisor: {
            name: "",
            registration_number: "",
          },
          last_ohc: "",
          health_plan: "",
        },
      },
      employeeSupervisor: "",
    };
  },
  mounted() {
    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getEmployees", {
        customer_id: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getAreas", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getDepartments", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getPositions", {
        customerId: this.$route.params.customerId,
      });
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Cliente" });
      this.$router.push("/");
    }

    if (this.$route.params.employeeId) {
      this.$store.dispatch("employee/getEmployee", {
        employeeId: this.$route.params.employeeId,
      });

      this.$store.dispatch("tags/getEmployeeTags", {
        employee_id: this.$route.params.employeeId,
      });
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", {
        frontelement: "do Colaborador",
      });
      this.$router.push("/");
    }

    this.updateList();
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;

      if (this.customer) {
        this.breadcrumbList[1].label = `${this.customer.company_name}`;
      }
    },
    onSelectSupervisor(supervisor) {
      if (supervisor == "none") {
        this.employeeSupervisor = "";
        this.employee.functional_data.supervisor = {
          name: null,
          registration_number: null,
        };
        return;
      }
      this.employeeSupervisor = supervisor.name;
      this.employee.functional_data.supervisor = {
        name: supervisor.name,
        registration_number: supervisor.registration_number,
      };
    },
    isEmployeeValid(employee) {
      //Name
      if (!employee.name || employee.name.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Nome" });
        return false;
      }
      //E-mail
      if (
        employee.email &&
        employee.email.trim() !== "" &&
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          employee.email.trim()
        )
      ) {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", { field: "E-mail" });
        return false;
      }
      let checkEmail = (obj) =>
        obj.email === employee.email && obj.id !== employee.id;
      if (employee.email && this.customer_employees.some(checkEmail)) {
        window.frontMsg("FRNT_IN_USE_WARNING", { field: "E-mail" });
        return false;
      }
      //CPF
      let checkCpf = (obj) =>
        obj.cpf === employee.cpf && obj.id !== employee.id;

      if (employee.cpf && validCPF(employee.cpf) === false) {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", { field: "CPF" });
        return false;
      }
      if (employee.cpf && this.customer_employees.some(checkCpf)) {
        window.frontMsg("FRNT_IN_USE_WARNING", { field: "CPF" });
        return false;
      }
      if (
        (employee.cellphone.replace(/[\s()+-]/g, "").length < 12 ||
          employee.cellphone.replace(/[\s()+-]/g, "").length > 13) &&
        employee.cellphone.trim() !== ""
      ) {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
          field: "Número de Celular",
        });
        return false;
      }
      //Reg. Number
      if (
        !employee.registration_number ||
        employee.registration_number.trim() === ""
      ) {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Número de Registro" });
        return false;
      }
      for (let colab of this.customer_employees) {
        if (colab.cellphone) {
          if (
            colab.cellphone.replace(/[\s()-]/g, "") ===
              employee.cellphone.replace(/[\s()-]/g, "") &&
            colab.id !== employee.id
          ) {
            window.frontMsg("FRNT_IN_USE_WARNING", {
              field: "Número de Celular",
            });
            return false;
          }
        }
        if (
          colab.registration_number.replace(/[\s()-]/g, "") ===
            employee.registration_number.replace(/[\s()-]/g, "") &&
          colab.id !== employee.id
        ) {
          window.frontMsg("FRNT_IN_USE_WARNING", {
            field: "Número de Registro",
          });
          return false;
        }
      }
      //Employee other data
      if (
        Number(employee.anamnesis_data.height) &&
        Number(employee.anamnesis_data.height) < 0
      ) {
        window.frontMsg("FRNT_NUM_RANGE_WARNING", {
          field: "Altura",
          numbercondition: "maior que zero",
        });
        return false;
      }
      if (
        Number(employee.anamnesis_data.weight) &&
        Number(employee.anamnesis_data.weight) < 0
      ) {
        window.frontMsg("FRNT_NUM_RANGE_WARNING", {
          field: "Peso",
          numbercondition: "maior que zero",
        });
        return false;
      }
      if (
        Number(employee.functional_data.work_week) &&
        Number(employee.functional_data.work_week) < 0
      ) {
        window.frontMsg("FRNT_NUM_RANGE_WARNING", {
          field: "Jornada",
          numbercondition: "maior que zero",
        });
        return false;
      }
      return true;
    },
    updateEmployee() {
      event.preventDefault();
      if (this.isEmployeeValid(this.employee)) {
        let employeeJson = Object.assign({}, this.employee);
        if (employeeJson.cellphone)
          employeeJson.cellphone = employeeJson.cellphone.replace(
            /[\s()-]/g,
            ""
          );
        if (employeeJson.anamnesis_data.weight)
          employeeJson.anamnesis_data.weight = parseFloat(
            employeeJson.anamnesis_data.weight
          );
        if (employeeJson.anamnesis_data.height)
          employeeJson.anamnesis_data.height =
            parseFloat(employeeJson.anamnesis_data.height) || "";
        if (employeeJson.functional_data.work_week)
          employeeJson.functional_data.work_week = Math.round(
            employeeJson.functional_data.work_week
          );
        if (employeeJson.functional_data.last_ohc) {
          employeeJson.functional_data.last_ohc = formatDateAPI(
            employeeJson.functional_data.last_ohc
          );
        }
        if (employeeJson.date_of_birth) {
          employeeJson.date_of_birth = formatDateAPI(
            employeeJson.date_of_birth
          );
        }
        if (!employeeJson.functional_data.supervisor?.name) {
          employeeJson.functional_data.supervisor = {
            name: null,
            registration_number: null,
          };
        }
        delete employeeJson.customer_id;
        employeeJson = nullEmptyProps(employeeJson);
        this.$store.dispatch("employee/updateEmployee", {
          employee: employeeJson,
          customer: this.customer,
        });
      }
    },
    changeTab(newTab) {
      this.tabSelected = newTab;
    },
    selectDisease(disease) {
      if (this.employee.anamnesis_data.preexisting_conditions.includes(disease))
        this.employee.anamnesis_data.preexisting_conditions =
          this.employee.anamnesis_data.preexisting_conditions.filter(
            (x) => x !== disease
          );
      else this.employee.anamnesis_data.preexisting_conditions.push(disease);
    },
  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    customer() {
      if (this.customer) {
        this.$store.dispatch("employee/getEmployees", {
          customer_id: this.customer.id,
        });
        this.updateList();
      }
    },
    computedEmployee() {
      if (this.computedEmployee) {
        this.$store.dispatch("tags/getEmployeeTags", {
          employee_id: this.computedEmployee.id,
        });

        this.employee = {
          id: this.computedEmployee.id ?? "",
          name: this.computedEmployee.name ?? "",
          email: this.computedEmployee.email ?? "",
          cellphone: this.computedEmployee.cellphone ?? "",
          cpf: this.computedEmployee.cpf ?? "",
          registration_number: this.computedEmployee.registration_number ?? "",
          gender: this.computedEmployee?.gender ?? "",
          date_of_birth: this.computedEmployee?.date_of_birth ?? "",
          anamnesis_data: {
            height: this.computedEmployee.anamnesis_data?.height ?? "",
            weight: this.computedEmployee.anamnesis_data?.weight ?? "",
            smoking: this.computedEmployee.anamnesis_data?.smoking ?? "",
            preexisting_conditions:
              this.computedEmployee.anamnesis_data?.preexisting_conditions ??
              [],
            physical_activity:
              this.computedEmployee.anamnesis_data?.physical_activity ?? "",
            medicines_for_continuous_use:
              this.computedEmployee.anamnesis_data
                ?.medicines_for_continuous_use ?? "",
            blood_pressure_level:
              this.computedEmployee.anamnesis_data?.blood_pressure_level ?? "",
            pregnancy: this.computedEmployee.anamnesis_data?.pregnancy ?? "",
          },
          functional_data: {
            area: this.computedEmployee.functional_data?.area ?? "",
            department: this.computedEmployee.functional_data?.department ?? "",
            position: this.computedEmployee.functional_data?.position ?? "",
            work_shift: this.computedEmployee.functional_data?.work_shift ?? "",
            work_week: this.computedEmployee.functional_data?.work_week ?? "",
            supervisor: this.computedEmployee.functional_data?.supervisor ?? "",
            last_ohc: this.computedEmployee.functional_data?.last_ohc ?? "",
            health_plan:
              this.computedEmployee.functional_data?.health_plan ?? "",
          },
        };

        this.employeeSupervisor =
          this.computedEmployee.functional_data?.supervisor?.name ?? "";
      }
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    computedEmployee() {
      return this.$store.getters["employee/employee"];
    },
    customer_employees() {
      return this.$store.getters["employee/employees"];
    },
    functionalDataSelected() {
      return this.tabSelected === "functionalData";
    },
    diseasesList() {
      return this.employee.anamnesis_data.preexisting_conditions.join(", ");
    },
    anamnesisSelected() {
      return this.tabSelected === "anamnesis";
    },
    area_options() {
      return this.$store.getters["employee/areas"].map((area) => area.area);
    },
    department_options() {
      return this.$store.getters["employee/departments"].map(
        (department) => department.department
      );
    },
    position_options() {
      return this.$store.getters["employee/positions"].map(
        (position) => position.position
      );
    },
    filteredSupervisors() {
      if (this.employeeSupervisor) {
        return this.customer_employees.filter((employee) => {
          return (
            employee.name
              .toLowerCase()
              .includes(this.employeeSupervisor.toLowerCase()) &&
            employee.id !== this.employee.id &&
            employee.functional_data?.position?.toLowerCase() === "supervisor"
          );
        });
      }
      return this.customer_employees.filter(
        (employee) =>
          employee.id !== this.employee.id &&
          employee.functional_data?.position?.toLowerCase() === "supervisor"
      );
    },
    formatedLastOHC: {
      get: function () {
        return formatDate(this.employee.functional_data.last_ohc);
      },
      set: function (value) {
        this.employee.functional_data.last_ohc = value;
      },
    },
    formatedDateOfBirth: {
      get: function () {
        return formatDate(this.employee.date_of_birth);
      },
      set: function (value) {
        this.employee.date_of_birth = value;
      },
    },
  },
  components: {
    Breadcrumb,
    TwInput,
  },
};
</script>
