import { api } from "../../services/api";
import { displayErrMsg } from "../../utils/errors";

export default {
  namespaced: true,
  state: {
    departamentos: [],
  },
  mutations: {
    async SAVE_EMPLOYEE_DEPARTAMENTO(state, { data }) {
      await api
        .post(`customers/${data.customer_id}/employees/departments`, {
          department: data.departamento,
        })
        .then(() => {
          state.departamentos.push({ department: data.departamento });
          // window.toastr.success(
          //   "Adicionado com sucesso",
          //   "Colaborador - Departamento"
          // );
          displayErrMsg("GLOBAL_SUCCESS_ADD", 'Colaborador - Departamento', 'SAVE_EMPLOYEE_DEPARTAMENTO');
        })
        .catch((err) => {
          // if (err.response.data.subcode !== "TOKEN_EXPIRED") {
          //   window.toastr.error(
          //     "Erro ao adicionar",
          //     "Colaborador - Departamento"
          //   );
          // }
          displayErrMsg(err.response.data.code, 'Colaborador - Departamento', 'SAVE_EMPLOYEE_DEPARTAMENTO');
        });
    },
    async DELETE_EMPLOYEE_DEPARTAMENTO(state, { data }) {
      await api
        .delete(
          `customers/${
            data.customer_id
          }/employees/departments?department=${encodeURIComponent(
            data.departamento
          )}`
        )
        .then(() => {
          displayErrMsg("GLOBAL_SUCCESS_DELETE", 'Colaborador - Departamento', 'DELETE_EMPLOYEE_DEPARTAMENTO');
          state.departamentos = state.departamentos.filter(
            (departamento) => departamento.department !== data.departamento
          );
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Colaborador - Departamento', 'DELETE_EMPLOYEE_DEPARTAMENTO');
        });
    },
    async GET_EMPLOYEES_DEPARTAMENTO(state, { data }) {
      await api
        .get(`customers/${data.customer_id}/employees/departments`)
        .then((res) => {
          state.departamentos = res.data;
        });
    },
  },
  getters: {
    departamentos: (state) => state.departamentos,
  },
  actions: {
    getEmployeeDepartamentos(context, data) {
      context.commit("GET_EMPLOYEES_DEPARTAMENTO", { data });
    },
    deleteEmployeeDepartamentos(context, data) {
      context.commit("DELETE_EMPLOYEE_DEPARTAMENTO", { data });
    },
    saveEmployeeDepartamentos(context, data) {
      context.commit("SAVE_EMPLOYEE_DEPARTAMENTO", { data });
    },
  },
};
